/** https://dev.to/dharmi/adding-fonts-in-nextjs-local-fonts-along-with-styled-components-2cdd */
import { css } from 'styled-components'
import { getAssetsPath } from '@shared/lib'
import { FONTS, BASE_FONT_URL } from '@shared/config'

export const Fonts = css`
  ${FONTS.map(
    ({ name, fontWeight }) => css`
      @font-face {
        font-family: 'Montserrat';
        src:
          url('${getAssetsPath(`${BASE_FONT_URL}/${name}/${name}.woff2`)}') format('woff2'),
          url('${getAssetsPath(`${BASE_FONT_URL}/${name}/${name}.woff`)}') format('woff');
        font-weight: ${fontWeight};
        font-style: normal;
        font-display: swap;
      }
    `
  )}
`
