export const FONTS = Object.freeze([
  {
    name: 'Montserrat-Light',
    fontWeight: 300,
  },
  {
    name: 'Montserrat-Regular',
    fontWeight: 400,
  },
  {
    name: 'Montserrat-Medium',
    fontWeight: 500,
  },
  {
    name: 'Montserrat-SemiBold',
    fontWeight: 600,
  },
  {
    name: 'Montserrat-Bold',
    fontWeight: 700,
  },
  {
    name: 'Montserrat-Black',
    fontWeight: 900,
  },
])

export const BASE_FONT_URL = '/fonts'
